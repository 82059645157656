var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loggedIn)?_c('v-card',{staticClass:"mx-auto mt-6 pb-2 "},[_c('v-toolbar',{attrs:{"ligth":"","cards":"","flat":"","color":"green lighten-4"}},[_c('v-card-title',{staticClass:"text-h6 font-weight-regular"},[_vm._v(" Smart Devices ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-10",attrs:{"color":"primary","dark":"","fab":""},on:{"click":_vm.addToken}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-key-plus ")])],1)]}}],null,false,2206698140)},[_c('span',[_vm._v("Key hinzufügen")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-10 ml-5",attrs:{"color":"primary","dark":"","fab":""},on:{"click":_vm.addItem}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-playlist-plus ")])],1)]}}],null,false,2400409370)},[_c('span',[_vm._v("Device hinzufügen")])]),_c('v-dialog',{attrs:{"width":_vm.ctrlConfirmDel.width},model:{value:(_vm.ctrlConfirmDel.isVisisble),callback:function ($$v) {_vm.$set(_vm.ctrlConfirmDel, "isVisisble", $$v)},expression:"ctrlConfirmDel.isVisisble"}},[_c('ctrlConfirmDel',{ref:"ctrlConfirmDel",attrs:{"text":_vm.ctrlConfirmDel.text,"delName":_vm.ctrlConfirmDel.delName},on:{"deleteItemConfirm":_vm.deleteItemConfirm,"close":_vm.closeConfirmDel}})],1),_c('v-dialog',{attrs:{"width":_vm.ctrlAEItem.width},model:{value:(_vm.ctrlAEItem.isVisisble),callback:function ($$v) {_vm.$set(_vm.ctrlAEItem, "isVisisble", $$v)},expression:"ctrlAEItem.isVisisble"}},[_c('ctrlAddEditItem',{ref:"ctrlAEItem",attrs:{"item":_vm.ctrlAEItem.item,"device_types":_vm.ctrlAEItem.device_types,"options":_vm.ctrlAEItem.options,"isVisible":_vm.ctrlAEItem.isVisisble},on:{"update:isVisible":function($event){return _vm.$set(_vm.ctrlAEItem, "isVisisble", $event)},"update:is-visible":function($event){return _vm.$set(_vm.ctrlAEItem, "isVisisble", $event)},"close":function($event){_vm.ctrlAEItem.isVisisble = false}}})],1)],1),_c('v-card',{staticClass:"my-12 mx-6",attrs:{"tile":"","outlined":""}},[_c('v-card-title',[_vm._v(" Available Keys "),_c('v-spacer')],1),_c('v-data-table',{ref:"datatableToken",attrs:{"headers":_vm.tokenHeaders,"items":_vm.tokens,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"disabled":!_vm.access.delete_accept || item.roleLevel > _vm.user.roleLevel},on:{"click":function($event){return _vm.deleteItem(item, 'token')}}},[_vm._v(" mdi-delete ")])]}},{key:"item.copyKey",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){return _vm.copy2clipboard(item.token)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-content-copy ")])]}}],null,true)},[_c('span',[_vm._v("Copy to clipboard")])])]}}],null,false,3553490141)})],1),_c('v-card',{staticClass:"my-12 mx-6",attrs:{"tile":"","outlined":""}},[_c('v-card-title',[_vm._v(" Smart Devices "),_c('v-spacer')],1),_c('v-data-table',{ref:"datatable",attrs:{"headers":_vm.showHeaders,"items":_vm.devices,"search":_vm.search,"show-expand":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"disabled":(!_vm.access.edit_accept || item.roleLevel > _vm.user.roleLevel) && _vm.user.id != item.id},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"disabled":!_vm.access.delete_accept || item.roleLevel > _vm.user.roleLevel},on:{"click":function($event){return _vm.deleteItem(item, 'device')}}},[_vm._v(" mdi-delete ")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-subheader',{staticClass:"mt-2"},[_vm._v("Token:")]),_c('v-container',{staticClass:"mb-4"},[_c('v-row',[_vm._v(" "+_vm._s(item.shortToken)+" ")])],1)],1)]}}],null,false,3598635524)})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }