<template>

            <v-card
            v-if="loggedIn"
            class="mx-auto mt-6 pb-2 "
                >    
                <v-toolbar
                    ligth
                    cards                
                    flat
                    color="green lighten-4"
                >
                    <v-card-title class="text-h6 font-weight-regular">
                    Smart Devices
                    </v-card-title>
                        <v-spacer> </v-spacer>
                       <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                        color="primary"
                                        dark
                                        fab
                                        v-bind="attrs"
                                        v-on="on"
                                        class="mt-10"
                                        @click="addToken"
                                    >
                                        <v-icon dark>
                                            mdi-key-plus
                                        </v-icon>
                                    </v-btn>
                            </template>
                            <span>Key hinzufügen</span>                           
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                        color="primary"
                                        dark
                                        fab
                                        v-bind="attrs"
                                        v-on="on"
                                        class="mt-10 ml-5"
                                        @click="addItem"
                                    >
                                        <v-icon dark>
                                            mdi-playlist-plus
                                        </v-icon>
                                    </v-btn>
                            </template>
                            <span>Device hinzufügen</span>                           
                        </v-tooltip>
                    <v-dialog v-model="ctrlConfirmDel.isVisisble" :width="ctrlConfirmDel.width">
                        <ctrlConfirmDel 
                            ref="ctrlConfirmDel" 
                            :text= "ctrlConfirmDel.text"
                            :delName="ctrlConfirmDel.delName"
                            @deleteItemConfirm="deleteItemConfirm"
                            @close="closeConfirmDel">
                        </ctrlConfirmDel>              
                    </v-dialog>
                    <v-dialog v-model="ctrlAEItem.isVisisble" :width="ctrlAEItem.width" >
                        <ctrlAddEditItem 
                            ref="ctrlAEItem" 
                            :item="ctrlAEItem.item"
                            :device_types="ctrlAEItem.device_types"
                            :options="ctrlAEItem.options"
                            :isVisible.sync="ctrlAEItem.isVisisble"
                            @close="ctrlAEItem.isVisisble = false">
                        </ctrlAddEditItem>              
                    </v-dialog>                  
                </v-toolbar>

                <v-card tile outlined class="my-12 mx-6"> 
                     <v-card-title>
                        Available Keys
                        <v-spacer></v-spacer>
                    </v-card-title>
                    <v-data-table
                        ref="datatableToken"
                        :headers="tokenHeaders"
                        :items="tokens"
                        :items-per-page="-1"
                        hide-default-footer
                    >
                        <template v-slot:item.actions="{ item }">
                            <v-icon
                                :disabled="!access.delete_accept || item.roleLevel > user.roleLevel"
                                @click="deleteItem(item, 'token')"
                            >
                                mdi-delete
                            </v-icon>
                        </template>
                       <template v-slot:item.copyKey="{ item }">
                            <v-tooltip bottom>
                                                          <template v-slot:activator="{ on, attrs }">
                                       <v-icon
                                       v-bind="attrs"
                                            v-on="on"
                                            @click="copy2clipboard(item.token)"
                                        >
                                            mdi-content-copy
                                        </v-icon>
                                        </template>
                                <span>Copy to clipboard</span>
                            </v-tooltip>
                        </template>                                       
                    </v-data-table>
                </v-card>
                <v-card tile outlined class="my-12 mx-6"> 
                    <v-card-title>
                        Smart Devices
                        <v-spacer></v-spacer>
     
                    </v-card-title>

                    <v-data-table
                        ref="datatable"
                        :headers="showHeaders"
                        :items="devices"
                        :search="search"
                        show-expand
                    > 
                        <template v-slot:item.actions="{ item }">
                            <v-icon    
                                :disabled="(!access.edit_accept || item.roleLevel > user.roleLevel) && user.id != item.id"
                                class="mr-2"
                                @click="editItem(item)"
                            >
                                mdi-pencil
                            </v-icon>
                            <v-icon
                                :disabled="!access.delete_accept || item.roleLevel > user.roleLevel"
                                @click="deleteItem(item, 'device')"
                            >
                                mdi-delete
                            </v-icon>
                        </template>  
                    <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length">
                            <v-subheader class="mt-2">Token:</v-subheader>
                            <v-container class="mb-4">
                            <v-row>
                               {{item.shortToken}}                             
                            </v-row>
                            </v-container>
                        </td>
                    </template>             
                   </v-data-table>
                </v-card>
            </v-card>
  
</template>


<script>

// import dlgAddEditUser from "../../components/dialogs/addEditUser.vue"
// //import ctrlConfirmDel from "../../components/dialogs/ConfirmDeleteItem.vue"
// import dlgShowMap from "../../components/dialogs/showitemInMap.vue"
// import dlgImportitems from "../../components/dialogs//importitems.vue"

// import CompanyService from "../../services/company.service";
// import EventBus from '../../services/event-bus.service';

export default {

   components: {
        ctrlAddEditItem: () => import('../../components/ctrl/addEditSmartDevice.vue'),
        ctrlConfirmDel: () => import('../../components/ctrl/confirmDeleteItem.vue'),
        // dlgShowMap,
        // dlgImportitems,
    },  

    data () {
        return {
            name: "itemsViewer",
            compid:'',

 
            ctrlAEItem:{
                isVisisble: false,
                width: 900,
                item:{},
                options:{
                    title: 'Smart Device',                    
                    cultivationSpecies:[],
                    center: undefined,
                    isEdit: false
                }

            },
            ctrlConfirmDel:{
                width: 500,
                isVisisble: false,
                text: "Wollen Sie das Smart Device wirklich löschen?" ,
                delName: '',
                item: undefined
            },

            search: '',
            headers: [
                // { text: 'Num', align: 'start',sortable: false, value: 'index',},
                { text: 'Actions', value: 'actions', sortable: false },
                { text: 'Name', align: 'start',value: 'name',},
                { text: 'Hersteller', value: 'manufacturer' },
                { text: 'Typ', value: 'type' },
                { text: 'Device Typ', value: 'device_type_caption' },
                { text: '', value: 'data-table-expand' },
                // { text: 'Key', value: 'shortToken' , sortable: false},  
    
            ],  
            tokenHeaders: [
                // { text: 'Num', align: 'start',sortable: false, value: 'index',},
                { text: 'Actions', value: 'actions', sortable: false },
                { text: 'Name', align: 'start',value: 'name', sortable: false},
                { text: 'Erstellungsdatum', value: 'creationDate' , sortable: false},
                { text: 'Key', value: 'shortToken' , sortable: false},    
                { text: 'Copy Key', value: 'copyKey' , sortable: false},    
            ], 
            item2delete:"" 
 
        }
    },

  
    computed: {

        showHeaders: function(){

            return this.headers;
        },

        loggedIn: function() {

            return this.$store.state.auth.status.loggedIn
            
        },
 
        user(){
            return this.$store.state.auth.user
        },

        access(){
            return this.$store.state.app.access
        },   

        company() {
            return this.$store.state.app.actCompany.meta
        },

        tokens(){
            return this.$store.state.app.tokens
        },

        devices(){
             return this.$store.state.app.devices.smartdevices
        },

        device_types(){
             return this.$store.state.app.devices.device_types
        }


        // showItems: function() {
        //     if(!this.items.items)
        //         return [];

        //     return this.items.items.map(
        //                                 (items, index) => ({
        //                                     ...items,
        //                                     index: index
        //                                 }))
        // },

        
    },
    methods:{

        closeCtrl(event){
           console.debug('closeCtrl', event);
           this.ctrlSFIM.isVisisble = false;
 
        },

        copy2clipboard(value) {
            navigator.clipboard.writeText(value)
                .then(() => {
                console.debug('Text is on the clipboard.', value);
                this.message = 'Code copied to clipboard.';
                })
            .catch(e => {
                console.error(e);
                this.message = 'Sorry, unable to copy to clipboard.'
            });    
        },

        addToken () {
            console.debug('addToken');
             this.$store.dispatch("app/postApiSetStore", {path:"api/company/smartdevices/token", param:{store:"tokens", "id":this.company.id,"uid":this.company.uid}})
                .then((response)=>{ 

                    console.log("submit:", response); 

                })
                .catch((error)=>{
                    console.error(error);
                })            

        },

       deleteToken(){
            console.debug('deleteToken');
            this.$store.dispatch("app/deleteApiSetStore", {path:"api/company/smartdevices/token", param:{store:"tokens","compid":this.company.id, "tokenid":this.ctrlConfirmDel.item.id}})
            .then((response)=>{ 

                console.log("Token deleted:", response)
                this.ctrlConfirmDel.isVisisble = false; 

            })
            .catch((error)=>{
                console.error(error);
            })

        },

        deleteDevice(){
            console.debug('deleteDevice');
            this.$store.dispatch("app/deleteApiSetStore", {path:"api/company/smartdevice", param:{ store:"devices/smartdevices", "compid":this.company.id, "id":this.ctrlConfirmDel.item.id}})
            .then((response)=>{ 

                console.log("Device deleted:", response)
                this.ctrlConfirmDel.isVisisble = false; 

            })
            .catch((error)=>{
                console.error(error);
            })

        },        


 
        addItem () {
            console.debug('addItem');
            this.ctrlAEItem.options.isEdit = false;
            this.ctrlAEItem.device_types = this.device_types
            this.ctrlAEItem.isVisisble = !this.ctrlAEItem.isVisisble;
        },

        editItem (item) {
            console.debug('editItem',item);
            this.ctrlAEItem.options.isEdit = true;
            this.ctrlAEItem.item = item;
            this.ctrlAEItem.device_types = this.device_types
            this.ctrlAEItem.isVisisble = !this.ctrlAEItem.isVisisble;
        },

        deleteItem (item, item2delete) {
            console.debug('deleteItem', item, item2delete);
            this.item2delete = item2delete;
            this.ctrlConfirmDel.delName = item.name
            this.ctrlConfirmDel.isVisisble = true;
            this.ctrlConfirmDel.item = item;
        },

        deleteItemConfirm () {
            console.debug('deleteItemConfirm', this.item2delete);
            this.ctrlConfirmDel.isVisisble = false;

            if(this.item2delete == "token")
                this.deleteToken();
            else
                this.deleteDevice()

            this.item2delete = "";
 
        },

        closeConfirmDel(){
             console.debug('closeConfirmDel');
             this.ctrlConfirmDel.isVisisble = false;        
        },

        enabledChanged(item){
            console.debug('enabledChanged', item);
            // CompanyService.patchitemEnabled(this.company.id, item).then(
            //     (response) => {
                
            //         console.log("enabledChanged:", response);
                    
            //     },
            //     (error) => {
            //     console.log((error.response &&
            //         error.response.data &&
            //         error.response.data.message) ||
            //         error.message ||
            //         error.toString())
            //     }
            // );
        },

        submit(item) {
            console.debug('submit', item);
        },
        close () {
            console.debug('close');
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },


        refreshContent: function(){
            console.debug('refreshContent');
            const comp = this.company

            this.$store.dispatch("app/getApiSetStore", {path:"api/company/access", param:{store:"access", id:comp.id, content:"smartdevices"}
            }).catch((error)=>{
                console.error(error);
            })
            
            this.$store.dispatch("app/getApiSetStore", {path:"api/company/smartdevices/tokens", param:{store:"tokens" , id:comp.id}
            }).catch((error)=>{
                console.error(error);
            })
            
            this.$store.dispatch("app/getApiSetStore", {path:"api/company/smartdevices", param:{store:'devices', id:comp.id}
            }).catch((error)=>{
                console.error(error);
            })


        },

        refreshTokens(){
            console.debug('refreshTokens');
            // CompanyService.getTokens(this.company.id).then(
            //     (response) => {
                
            //         console.log("refreshTokens res:", response);
            //         this.tokens = response.data;
            //     },
            //     (error) => {
            //     //todo
            //     this.items= [];
            //     console.log((error.response &&
            //         error.response.data &&
            //         error.response.data.message) ||
            //         error.message ||
            //         error.toString())
            //     }
            // );
        },

 
    },

    mounted() {

        //this.refreshNavbar();
        console.debug('mounted');
        this.refreshContent();

        // var id = this.company.id;
        // if (typeof(this.$route.params.id) != "undefined"){
        //             this.id = this.$route.params.id;
        // }

        // if(id == ''){
        //     this.message = 'Error no company id'; 
        //     return;           
        // }

        // if (typeof(id) != "undefined"){
        //     console.debug('mounted id', id)
        //     this.id = id;
        //     this.refreshContent();
        // }
 

    },

}
</script>